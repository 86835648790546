
import { defineComponent, PropType, toRef } from 'vue';
import { PaymentsAccountTransactionsFilter, PaymentsAccountTransaction } from '@/models/Interfaces';

export default defineComponent({
    props: {
        filter: {
            type: Object as PropType<PaymentsAccountTransactionsFilter>,
            required: true
        },
        total: {
            type: Number,
            required: true
        },
        totalFiltered: {
            type: Number,
            required: true
        },
        hasFilter: {
            type: Boolean,
            required: true
        },
        totalText: {
            type: String,
            default: 'label.total'
        },
        totalFilteredText: {
            type: String,
            default: 'label.total-rows'
        },
        searchPlaceholder: {
            type: String
        }
    },
    emits: ['search', 'showAll', 'openFilter'],
    async setup(props, { emit }) {
        const filterObj = toRef(props, 'filter');
        async function search() {
            emit('search');
        }
        async function showAll() {
            emit('showAll');
        }
        async function openFilter() {
            emit('openFilter');
        }

        return { search, showAll, openFilter, filterObj };
    }
});
