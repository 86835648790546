
import { defineComponent, ref, onMounted, onUnmounted, computed, inject } from 'vue';
import { PaymentsAccountTransactionsFilter, PaymentsAccountTransaction } from '@/models/Interfaces';
import PaymentsAccountTransactionsFilterModal from '@/components/Modals/PaymentsAccountTransactionsFilterModal.vue';
import SimplePageHeader from '@/components/SimplePageHeader.vue';
import SimplePageFilter from '@/components/SimplePageFilter.vue';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import q from 'q';
import { PaymentType, toCamelCase } from '@/models/Enums';
import { ApiResult } from '@/services/BaseApi';
import { AwaitTaskResponse } from '@/models/Interfaces';
import { useI18n } from 'vue-i18n';
import { generalStore } from '@/store';

export default defineComponent({
    components: {
        SimplePageHeader,
        SimplePageFilter,
        PaymentsAccountTransactionsFilterModal
    },
    name: 'PaymentsAccountTransactions',
    async setup() {
        const modalPaymentsAccountTransactionsFilter = ref<InstanceType<typeof PaymentsAccountTransactionsFilterModal>>();
        const paymentType = PaymentType;
        const rows = ref<PaymentsAccountTransaction[]>([]);
        const filter = ref(new PaymentsAccountTransactionsFilter());
        const totalInPayment = ref(0);
        const total = ref(0);
        const totalFiltered = ref(0);
        const totalAmountLocalCurrency = ref(0);
        const hasNext = ref(false);
        const busy = ref(false);
        const { t } = useI18n();
        const localCurrencySymbol = generalStore.state.localCurrencySymbol;
        const emitter: any = inject('emitter');
        const hasFilter = computed<boolean>(() => {
            if (filter.value.paymentType != null) {
                return true;
            }
            if (filter.value.totalFrom != null) {
                return true;
            }
            if (filter.value.totalTo != null) {
                return true;
            }
            if (filter.value.currencyId != null) {
                return true;
            }
            if (filter.value.paymentDateStart != null) {
                return true;
            }
            if (filter.value.paymentDateEnd != null) {
                return true;
            }
            if (filter.value.receiptNumber != null) {
                return true;
            }
            if (filter.value.receiptCreateDateEnd != null) {
                return true;
            }
            if (filter.value.receiptCreateDateStart != null) {
                return true;
            }
            return false;
        });

        function correctFilter(f: PaymentsAccountTransactionsFilter) {
            if (f.totalFrom == '') {
                f.totalFrom = null;
            } else if (f.totalFrom != null) {
                f.totalFrom = Number(f.totalFrom);
            }

            if (f.totalTo == '') {
                f.totalTo = null;
            } else if (f.totalTo != null) {
                f.totalTo = Number(f.totalTo);
            }

            if (f.receiptNumber == '') {
                f.receiptNumber = null;
            }
        }
        async function GetPaymentsAccountTransactions() {
            swal.showLoading();
            busy.value = true;
            correctFilter(filter.value);
            const apiPromise = api.GetPaymentsAccountTransactions(filter.value);
            await q.delay(400);
            const apiResult = await apiPromise;
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            if (!apiResult.data) {
                return;
            }
            if (filter.value.pageIndex == 0) {
                rows.value = apiResult.data.rows;
                total.value = apiResult.data.total;
                totalFiltered.value = apiResult.data.totalFiltered;
                totalAmountLocalCurrency.value = apiResult.data.totalAmountLocalCurrency;
                totalInPayment.value = 0;
            } else {
                rows.value = [...rows.value, ...apiResult.data.rows];
            }

            hasNext.value = apiResult.data.hasNext;
            filter.value.pageIndex++;
            busy.value = false;
            swal.close();
        }

        function ListenerGetPaymentsAccountTransactions() {
            if (hasNext.value && !busy.value) {
                GetPaymentsAccountTransactions();
            }
        }

        async function search() {
            filter.value.pageIndex = 0;
            rows.value = [];
            await GetPaymentsAccountTransactions();
        }

        async function showAll() {
            filter.value = new PaymentsAccountTransactionsFilter();
            rows.value = [];
            await GetPaymentsAccountTransactions();
        }

        async function openFilter() {
            const filterObj: PaymentsAccountTransactionsFilter = JSON.parse(JSON.stringify(filter.value));
            modalPaymentsAccountTransactionsFilter.value?.open(filterObj);
        }

        async function applyFilter(newFilter: PaymentsAccountTransactionsFilter) {
            rows.value = [];
            filter.value = newFilter;
            filter.value.pageIndex = 0;
            await GetPaymentsAccountTransactions();
        }

        async function awaitTask(action: () => Promise<ApiResult<AwaitTaskResponse>>, onSuccess: (url: string) => void, waitTime = 3000) {
            swal.fire(t('swalAction.processing'));
            swal.showLoading();
            await q.delay(waitTime);

            const resp = await action();
            if (resp.errorMessage) {
                await swal.fire({
                    icon: 'error',
                    text: resp.errorMessage
                });
                return;
            }
            if (resp.data?.url) {
                onSuccess(resp.data.url);
                return;
            }

            if (waitTime > 0) {
                awaitTask(action, onSuccess, waitTime);
            }
        }

        async function ExportXls() {
            const swalResult = await swal.fire({
                icon: 'info',
                title: t('alert.export-payments-account-transactions.confirm'),
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('alert.yes'),
                cancelButtonText: t('alert.no')
            });
            if (!swalResult.isConfirmed) {
                return;
            }
            await q.delay(200);
            swal.showLoading();
            const filterData: PaymentsAccountTransactionsFilter = JSON.parse(JSON.stringify(filter.value));
            const apiResult = await api.exportPaymentsAccountTransactions(filterData);
            await q.delay(400);
            if (apiResult.data?.error || !apiResult.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.data?.error || 'No data'
                });
                return;
            }
            const taskId = apiResult.data.taskId;
            awaitTask(
                () => api.waitExportTask(taskId),
                url => {
                    swal.fire({
                        icon: 'success',
                        title: t('alert.exportReady.payments-account-transactions'),
                        html: `
                    <div class="text-center my-4">
                        <a href="${url}" target="_blank">
                            <button role="button" class="popup-bookit-button my-1 px-4">${t('report.download')}</button>
                        </a>
                    </div>`,
                        showConfirmButton: false,
                        showCloseButton: true
                    });
                }
            );
        }

        const onMountedCall = async () => {
            await GetPaymentsAccountTransactions();
            emitter.on('isBottom', ListenerGetPaymentsAccountTransactions);
        };
        onMounted(onMountedCall);
        onUnmounted(() => {
            emitter.off('isBottom', ListenerGetPaymentsAccountTransactions);
        });
        return {
            rows,
            filter,
            total,
            totalFiltered,
            totalInPayment,
            hasFilter,
            paymentType,
            totalAmountLocalCurrency,
            localCurrencySymbol,
            modalPaymentsAccountTransactionsFilter,
            toCamelCase,
            search,
            showAll,
            openFilter,
            applyFilter,
            ExportXls
        };
    }
});
