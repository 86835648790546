
import { defineComponent, ref, reactive } from 'vue';
import OverlayModal from '../Calendar/OverlayModal.vue';
import Calendar from 'primevue/calendar';
import { PaymentsAccountTransactionsFilter, Currency, DateRange } from '@/models/Interfaces';
import Multiselect from '@vueform/multiselect';
import { PaymentType, ToOptions } from '@/models/Enums';
import { generalStore } from '@/store';
import useProduct from '@/modules/useProduct';
import useOrder from '@/modules/useOrder';
import moment from 'moment';
import InputNumber from 'primevue/inputnumber';

export default defineComponent({
    components: { OverlayModal, Multiselect, Calendar, InputNumber },
    name: 'PaymentsAccountTransactionsFilterModal',
    emits: ['applyFilter', 'clearAll'],
    async setup(props, { emit }) {
        const isOpen = ref(false);
        const paymentTypes = ToOptions(PaymentType, 'receiptType.', true);
        const filter = ref(new PaymentsAccountTransactionsFilter());
        const currencies = ref<Currency[]>([]);
        const paymentDateRange = reactive(new DateRange());
        const receiptCreateDateRange = reactive(new DateRange());

        const { dateRangeValidate } = useProduct();
        const { dateToddmmyyyy } = useOrder();
        currencies.value = generalStore.getters.currencies;
        function open(filterParam: PaymentsAccountTransactionsFilter) {
            isOpen.value = true;
            filter.value = filterParam;
            if (filter.value.paymentDateStart) {
                paymentDateRange.fromDate = moment(filter.value.paymentDateStart, 'DD/MM/YYYY').toDate();
            } else {
                paymentDateRange.fromDate = null;
            }
            if (filter.value.paymentDateEnd) {
                paymentDateRange.toDate = moment(filter.value.paymentDateEnd, 'DD/MM/YYYY').toDate();
            } else {
                paymentDateRange.toDate = null;
            }

            if (filter.value.receiptCreateDateStart) {
                receiptCreateDateRange.fromDate = moment(filter.value.receiptCreateDateStart, 'DD/MM/YYYY').toDate();
            } else {
                receiptCreateDateRange.fromDate = null;
            }
            if (filter.value.receiptCreateDateEnd) {
                receiptCreateDateRange.toDate = moment(filter.value.receiptCreateDateEnd, 'DD/MM/YYYY').toDate();
            } else {
                receiptCreateDateRange.toDate = null;
            }
        }
        function ApplyFilter() {
            if (paymentDateRange.fromDate) {
                filter.value.paymentDateStart = dateToddmmyyyy(paymentDateRange.fromDate);
            }
            if (paymentDateRange.toDate) {
                filter.value.paymentDateEnd = dateToddmmyyyy(paymentDateRange.toDate);
            }
            if (receiptCreateDateRange.fromDate) {
                filter.value.receiptCreateDateStart = dateToddmmyyyy(receiptCreateDateRange.fromDate);
            }
            if (receiptCreateDateRange.toDate) {
                filter.value.receiptCreateDateEnd = dateToddmmyyyy(receiptCreateDateRange.toDate);
            }
            emit('applyFilter', filter.value);
            isOpen.value = false;
        }
        function clearAll() {
            emit('clearAll');
            isOpen.value = false;
        }
        const dateChanged = (dateRange: DateRange) => {
            if (!dateRange.fromDate || !dateRange.toDate) return;
            dateRangeValidate(dateRange);
        };
        return {
            open,
            ApplyFilter,
            clearAll,
            dateChanged,
            isOpen,
            filter,
            paymentTypes,
            currencies,
            paymentDateRange,
            receiptCreateDateRange
        };
    }
});
